import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { Sensor } from '@/shared/models/dto/sensor';
import { Dashboard } from '../../shared/models/dto/dashboards';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';
import AuthStore from '../../stores/auth-store';
import { Company } from '../../shared/models/dto/account';

@Component({
    components: {
        apexChart: VueApexCharts,
        sensorChart: require('@/views/_components/device/sensor-chart.vue').default,
        sensorChartPeriodSelection: require('@/views/_components/dashboard/sensor-chart-period-selection.vue').default,
        sensorSelectDialog: require('@/views/home/sensor-select-dialog.vue').default
    }
})

export default class HomeUserComponent extends AuthComponentBase {
    get getItemCounts(): ItemCount[] {       
        return this.filteredItems.filter(x => this.AppConsts.auth.isGranted(x.permission));
    }

    itemCounts: ItemCount[] = [
        { key: 'Users', name: '', value: 0, color: 'rgba(0, 227, 150, 0.85)', to: 'user-list', permission: 'Permissions_User_Read' },
        { key: 'Gateways', name: '', value: 0, color: 'rgba(0, 143, 251, 0.85)', to: 'gateway-list', permission: 'Permissions_Gateway_Read' },
        { key: 'Devices', name: '', value: 0, color: 'rgba(119, 93, 208, 0.85)', to: 'device-list', permission: 'Permissions_Device_Read' },
        { key: 'Sensors', name: '', value: 0, color: 'rgba(255, 69, 96, 0.85)', to: null, permission: 'Permissions_Sensor_Read' }
    ];

    filteredItems: ItemCount[] = [];
    dashboard: Dashboard = {
        id: null,
        userId: null,
        dashboardItems: [],
        isDefault: null
    };

    showSensorSelectDialog = false;
    loading = false;

    sensors: Sensor[] = [];

    async getDashboardItems() {
        this.dashboard = {
            id: null,
            userId: null,
            dashboardItems: [],
            isDefault: null
        };

        const user = this.authStore.getUser();
        this.loading = true;
        if (user.id) {
            let request = `/api/dashboards/${user.id}`;
            if (this.AppConsts.auth.isGranted('Permissions_DashboardItem_Read')) {
                request = request + '?&expand=dashboardItems`';
            }

            await this.authService.get<IRestResponseDto<Dashboard>>(request, false).then(async response => {
                this.dashboard = <Dashboard><unknown>response.content;
                this.loading = false;
            });
        }        
    }

    async getItems() {
        await this.getDashboardItems();

        this.filteredItems = this.itemCounts.filter(x => this.AppConsts.auth.isGranted(x.permission));

        let request = `/api/companies/${AuthStore.getUser().companyId}`;

        const expands = this.filteredItems
            .filter(x => x.key.toLowerCase() !== 'sensors')
            .map(x => x.key + '(' + (x.key.toLowerCase() === 'users' ? '' : '$filter=IsDeleted eq false;') + '$count=true;$top=0)').join(',');

        if (expands.length) {
            request = request + `?$expand=${expands}`;
        }

        this.authService.get<IRestResponseDto<Company>>(request, false).then((response) => {
            const result = <Company><unknown>response.content;
            this.filteredItems
                .filter(x => x.key.toLowerCase() !== 'sensors')
                .forEach((item) => {
                    item.value = result[item.key.toLowerCase() + '@odata.count'];
                });
        });

        const sensorIndex = this.filteredItems.findIndex(x => x.key.toLowerCase() === 'sensors');
        const sensorItem = this.filteredItems[sensorIndex];
        if (this.AppConsts.auth.isGranted(sensorItem.permission)) {
            this.authService.get<IRestResponseDto<Sensor[]>>('/api/sensors?includeInactive=false&$count=true&$top=0', false).then((response) => {
                sensorItem.value = response.count;
                this.$set(this.filteredItems, sensorIndex, sensorItem);
            })
        }
    }

    async mounted() {
        this.itemCounts = this.itemCounts.map(x => { 
            return {
                ...x,
                name: this.t(x.key)
            }
        });

        await this.getItems();
    }

    deleteDashboardItem(id: number) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/dashboarditems/${id}`).then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('Successful'));
                        this.getDashboardItems();
                    }
                });
            }
        });
    }

    async close(mustReload: boolean) {
        this.showSensorSelectDialog = false;
        if (mustReload) {
            await this.getDashboardItems();
        }
    }
}

interface ItemCount { key: string; name: string; value: number; color: string; to: string; permission: string }